:root {
  --black: #000;
  --white: #fff;
  --orange: #ff8400;
  --green: rgb(0, 203, 132);
  --red: rgb(255, 0, 0);
  --grey: rgb(221, 221, 221);
}

body {
  background-color: rgb(255, 253, 251);
  box-sizing: border-box;
  font-family: Manrope, sans-serif;
}
a {
  color: var(--black);
  text-decoration: none;
  font-family: 'Noto Sans', sans-serif 
}

a:hover {
  color: var(--black);
}

input {
  font-family: 'Noto Sans', sans-serif 
}

th {
  font-family: 'Delicious Handrawn', cursive;
}

p {
  word-wrap: break-word;

}

.youtube-vid {
  height: 150px;
  width: 280px;
}

/* ############################# */
.dropzone {
  border: 2px dashed #ccc;
  padding: 50px;
  text-align: center;
  font-size: 1.2rem;
  border-radius: 10px;
}

.dropzone.active {
  border-color: #666;
}

.file-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.file-preview {
  display: flex;
  width: 170px;
  height: 150px;
  border-radius: 5px;
  /* margin-right: 10px; */
  overflow: hidden;
  position: relative;
}

.image-preview {
  display: block;
  width: 100%;
  height: 100%;

  background-size: cover;
  background-position: center;
}

.video-preview {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.file-icon {
  width: 80px;
  height: 80px;
}

.file-remove {
  position: absolute;
  border: none;
  top: 0;
  right: 0;
  background-color: var(--red);
  color: white;
  padding: 2px 8px;
  font-size: 0.8rem;
  border-radius: 5px;
}
.file-type {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 2px 5px;
  font-size: 0.8rem;
  border-radius: 0 0 5px 0;
}

/* ############################# */

/* ############################# */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* ############################# */

.dash-box {
  border: 2px solid var(--orange);
  background-color: #fdf0e4;
  border-radius: 5px;
  width: 100%;
}

.dash-sep {
  background-color: #00000025;
  border-radius: 5px;
  margin: 30px 40px;
  height: auto;
  width: 2px;
}

.stars {
  display: flex;
  list-style: none;
}

.star {
  font-size: 35px;
}

.fa-star {
  color: #c3c3c3;
  cursor: pointer;
}

.fa-trash:hover, .fa-clipboard-list:hover {
  color: var(--orange);
  cursor: pointer;
}

.navbar-brand:hover{
  color: var(--white);
}

fieldset {
  /* border: 2px solid var(--orange); */
  border-radius: 5px;
}

.w-fit {
  width: fit-content;
}

.libre{
  font-family: 'Libre Baskerville', serif;
}

.delicious {
  font-family: 'Delicious Handrawn', cursive;
}

.viaoda {
  font-family: 'Noto Sans', sans-serif 
}

.manrope {
  font-family: 'Manrope', sans-serif;
}

.bold-1 {
  font-weight: 500;
}
.bold-2 {
  font-weight: 600;
}
.bold-3 {
  font-weight: 900;
}
.text-4xl {
  font-size: 30px;
  font-family: 'Delicious Handrawn', cursive;
}
.text-3xl {
  font-size: 26px;
  font-family: 'Libre Baskerville', serif;
}
.text-xxl {
  font-size: 24px;
}
.text-xl {
  font-size: 22px;
}
.text-lg {
  font-size: 20px;
  font-family: 'Delicious Handrawn', cursive;
}
.text-md {
  font-size: 18px;
  font-family: 'Noto Sans', sans-serif 
}
.text-sm {
  font-size: 16px;
  font-family: 'Noto Sans', sans-serif 
}
.text-xs {
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif 
}
.text-xxs {
  font-size: 12px;
  font-family: 'Noto Sans', sans-serif 
}

.spaced {
  line-height: 2;
}

.coming-soon {
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.coming-soon-img {
  height: 250px;
  width: 220px;
}

.prev-wrapper {
  height: 150px;
}

.video-wrapper {
  height: 160px;
}

.prev-box {
  height: 95%;
  width: 95%;
}

.video-box {
  height: 150px;
  width: 100%;
}

.p-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.white {
  color: var(--white);
}

.green {
  color: var(--green);
}

.red {
  color: var(--red);
}

.yellow {
  color: #ffbb00;
}

.orange {
  color: var(--orange);
}

.bg-white {
  background-color: var(--white);
}

.bg-orange {
  background-color: var(--orange);
}

.border-orange {
  border: 2px solid var(--orange);
  border-radius: 5px;
}

.bg-green {
  background-color: var(--green);
}

.navbar-toggler{
  background-color: var(--black);
  color: var(--white);
}

.navbar-toggler-icon{
  height: 3px;
}

/* GENERALS */
.breadcrumb {
  cursor: pointer;
}

.wrapper, .about-wrapper {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.about-img {
  border-radius: 5px;
  min-width: 200px;
  min-height: 200px;
}

.about-img-wide {
  border-radius: 5px;
  width: 100%;
  height: 350px;
}

.border-orange{
  border: 1px solid var(--orange);
}

.wrapper-box, .review-box {
  background-color: var(--white);
  border-radius: 5px;
  min-width: 180px;
  width: 180px;
  border: 2px solid var(--orange);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.wrapper-box:not(:last-child), .review-box:not(:last-child){
  margin-right: 10px;
  /* min-width: 47%;
  width: 47%; */
}

.wrapper-box:hover {
  background-color: #ffdcbb;
}

.review-box {
  min-width: 310px;
  height: 280px;
  padding: 18px 15px;
  width: 310px;
}

.home-box {
  height: 320px;
}

.review-comment {
  white-space: pre-wrap;
}

.video-check {
  top: 8px;
  left: 5px;
  position: absolute;
  z-index: 100;
  width: 20px;
  height: 20px;
}

.video-check-num {
  background-color: var(--orange);
  border-radius: 5px;
  top: 5px;
  color: var(--white);
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
  right: 5px;
  width: 25px;
  height: 25px;
}
/* TABLE */
/* LOADER */
.loader-bg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffc28243;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 1111;
}

.loader {
  /* position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%; */
}

.loader::after{
  animation: lds-dual-ring 1.2s linear infinite;
  border: 6px solid #fa9c23;
  border-color: #fa9c23 transparent;
  border-radius: 50%;
  content: ' ';
  display: block;
  height: 64px;
  width: 64px;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropdown-menu[data-bs-popper] {
  border-top: 4px solid var(--orange);
  left: -75px;
  padding: 10px;
  top: 50px;
  width: 100%;
}

.upcoming {
  border-top: 2px solid #ca6200;
  border-bottom: 2px solid #ca6200;
}

.user-icon, .user-icon-sm {
  color: var(--white);
  border: 1px solid var(--orange);
  border-radius: 50%;
  background-color: var(--orange);
  display: flex;
  height: 38px;
  justify-content: center;
  align-items: center;
  width: 40px;
}

.user-icon-sm {
  border-radius: 5px;
  width: fit-content;
}

.previewImage {
  height: 150px;
  width: 150px;
}

.signin-btn {
  background-color: var(--white);
  color: var(--black);
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  border: 1px solid var(--white);
  border-radius: 5px;
  padding: 10px 30px;
  width: fit-content;
}

.signin-btn:hover {
  background-color: var(--orange);
  border: 1px solid var(--orange);
  color: var(--white);
}

.mid-container {
  background-color: var(--white);
  padding-top: 50px;
}

.table {
  display: block;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}

.table-nav-block {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.table-nav{
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  margin-left: 16px;
  font-family: 'Noto Sans', sans-serif 
}

.table-nav:hover{
  color: #272727;
}

.table-active{
  border-bottom: 3px solid #ffbb00;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

th {
  font-size: 14px;
  font-weight: 600;
  vertical-align: middle;
}

td {
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
}
/* TABLE CLOSE */

.player-preview {
  width: 100%;
  height: 180px;
}

.black-top {
  margin-top: 57px;
  border-top: 3px solid var(--orange);
}

.prev-vid {
  border-radius: 5px;
  height: 300px;
  width: 100%;
}

.course-vid {
  height: 120px;
  width: 200px;
}

.lock {
  position: absolute;
  height: 24px;
  width: 24px;
  right: 12px;
  bottom: 20px;
}

.academy-card:hover {
  color: var(--black);
  border-bottom: 5px solid var(--orange);
  border-radius: 5px;
}

.video-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
}

.upload-btn, .progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background-color: var(--black);
  border-radius: 5px;
  width: 100%;
  height: 45px;
}

.progress-bar {
  background-color: rgb(227, 227, 227);
  border: 1px solid rgb(227, 227, 227);
}

.inner-bar {
  display: flex;
  align-items: center;
  background-color: var(--black);
  justify-content: end;
  height: 100%;
}

.action-btn, .big-orange-btn, .enrolled {
  color: var(--white);
  background-color: var(--black);
  border-radius: 5px;
  padding: 6px 30px;
}

.action-btn:disabled, .upload-btn:disabled {
  background-color: darkgray;
  cursor: not-allowed}

.action-btn:hover, .upload-btn:hover {
  background-color: rgb(39, 39, 39);
  color: var(--white)
}

.big-orange-btn {
  background-color: var(--orange);
  border: none;
}

.enrolled {
  background-color: var(--green);
  width: fit-content;
}

.add-btn, .cross {
  background-color: #ffbb00;
  border: none;
  border-radius: 5px;
  padding: 5px 0;
  text-align: center;
  width: fit-content;
}

.cross {
  font-size: 22px;
  font-weight: 600;
  padding: 0px 10px;
}

.navbar-brand, .nav-link {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  color: var(--grey);
  /* font-family: 'Delicious Handrawn', cursive; */
}

.nav-link:hover, .nav-link:focus {
  color: var(--white);
}


.fit-content {
  width: fit-content;
}

.carousel-image{
  height: 60vh;
  width: 100%;
}

.carousel-text {
  color: var(--white);
  left: 15%;
  position: absolute;
  right: 15%;
  text-align: center;
  top: 25%;
  bottom: 25%;
  z-index: 1;
}

.carousel-header{
  font-size: 1.8rem;
  font-family: 'Libre Baskerville', serif;
}

.carousel-btn{
  background-color: var(--orange);
  color: var(--white);
  border: none;
  border-radius: 5px;
  padding: 13px 35px;
}

.carousel-btn-border{
  background-color: var(--white);
  color: var(--orange);
  border: 2px solid var(--orange);
  border-radius: 5px;
  padding: 13px 35px;
}

.carousel-btn:hover, .carousel-btn-border:hover{
  background-color: #ff922c;
  color: var(--white);
}

.margin-top-70 {
  margin-top: 70px;
}

.about {
  padding: 120px 0;
}

.omoye-img {
  height: 400px;
}

.recipe-img {
  height: 280px;
  width: 100%;
}

.recipe-img-sm, .course-vid {
  height: 150px;
  width: 100%;
}

/* ADMIN DASHBOARD */
.dash-info-box{
  background-image: -webkit-gradient(linear, left top, left bottom, from(#109874b7), to(#109874));
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px #d6d6d66e;
  height: 200px;
  margin-bottom: 20px;
  padding: 40px 10px;
}

.dash-text-top, .dash-text-bottom {
  color: var(--white);
}

.dash-text-top{
  font-size: 24px;
}

.dash-text-bottom{
  font-size: 12px;
  margin-top: 5px;
}
/* END ADMIN DASHBOARD */

/* ADMIN NAVIGATION */
.nav-icon{
  height: 18px;
  width: auto;
}

.user-nav-label {
  color: var(--black);
  display: block;
  margin-top: 5px;
}

.user-nav-block {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 70px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 100%;
}

.user-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
}

/* END ADMIN NAVIGATION */

/* FOOTER CSS */
.footer-content {
  padding-top: 60px;
  padding-bottom: 60px;
}
.footer-header{
  color: white;
  font-size: 18px;
  font-family: 'Delicious Handrawn', cursive;
}

.footer-link{
  color: rgb(201, 201, 201);
  font-size: 14px;
  font-weight: 200;
  margin: 8px 0;
  text-decoration: none;
  font-family: 'Noto Sans', sans-serif 
}

.footer-icon {
  height:40px;
  width:40px;
}

.footer-link:hover{
  color: rgb(255, 255, 255);
}

.footer-seperator{
  background-color: gray;
  height: 1px;
  margin-top: 80px;
  margin-bottom: 15px;
}

.copyright-text {
  color: rgb(201, 201, 201);
  font-weight: 200;
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif 
}


/* RESPONSIVE */
@media (max-width: 576px) {
  .wrapper-box-sm {
    min-width: 48%;
    width: 48%;
  }
}
@media (min-width: 576px) {
  .about-img-wide {
    height: 450px;
  }

  .prev-box {
    width: 70%;
  }

  .omoye-img {
    height: 600px;
  }
  .upload-btn, .progress-bar {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .youtube-vid {
    height: 250px;
    width: 450px;
  }

  .text-4xl {
    font-size: 35px;
  }
  .dash-sep {
    margin: 35px 55px;
  }
  .about-img-wide {
    height: 550px;
  }

  .about-wrapper {
    justify-content: center;
  }

  .coming-soon-img {
    height: 480px;
    width: 400px;
  }

  .prev-box {
    height: 100%;
    width: 50%;
  }

  .mid-container {
    background-color: var(--white);
    padding-top: 0;
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .carousel-image{
    height: 80vh;
  }
  .carousel-text {
    top: 30%;
  }
  .carousel-header{
    font-size: 4rem;
  }
  .upload-btn, .progress-bar {
    width: 40%;
  }
  .table {
    display: table;
  }
  .table-nav{
    font-size: 15px;
    margin-left: 24px;
  }
}
@media (min-width: 992px) {
  .about-img-wide {
    height: 750px;
  }

  .orange-hover:hover, .orange-hover:focus {
    background-color: var(--orange);
    border-radius: 5px;
  }
  
  .dropdown-menu[data-bs-popper] {
    width: 200px;
  }

  .signin-btn {
    margin-bottom: 0;
    padding: 5px 30px;
  }

  .omoye-img {
    height: 450px;
    width: 85%;
  }

  .wrapper-box {
    min-width: 250px;
    width: 250px;
  }

  .recipe-img-sm, .course-vid {
    height: 200px;
  }
  .user-nav-block {
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .user-nav-item {
    padding: 0;
    margin: 0;
  }
  .upload-btn, .progress-bar {
    width: 30%;
  }
}
@media (min-width: 1200px) {
  .about-img-wide {
    height: 900px;
  }
  .prev-wrapper {
    height: 200px;
  }
  .prev-box {
    height: 100%;
    width: 40%;
  }

  .mid-container {
    margin-left :auto;
    margin-right :auto;
    max-width: 75%;
  }
}
@media (min-width: 1400px) {
}
