:root {
    --black: #000;
    --white: #fff;
    --orange: #ff8400;
    --red: rgb(255, 0, 0);
}

.orange-btn:disabled:hover, .red-btn:disabled:hover, .qty-toggle:disabled:hover {
    cursor: not-allowed;
  }

.click {
    cursor: pointer;
}

.bg-orange {
    background-color: var(--orange);
}

.bg-white {
    background-color: var(--white);
}

.bg-black {
    background-color: var(--black);
}

.shadow-bottom{
    box-shadow: 0px 15px 10px -15px #afafaf;
}

.red-circle {
    font-size: 8px;
  }

/* FONT */
.text-1 {
    font-size: 12px;
}
.text-2 {
    font-size: 14px;
}
.text-3 {
    font-size: 16px;
}
.text-4 {
    font-size: 18px;
}
.text-5 {
    font-size: 20px;
}
.text-6 {
    font-size: 25px;
}
.text-7 {
    font-size: 30px;
}
.text-8 {
    font-size: 35px;
}
.shop-orange {
    color: var(--orange);
}
.orange {
    color: var(--orange);
}
.text-white {
    color: var(--white);
}
.text-white:hover {
    color: var(--orange);
}
.light-hr{
    color: rgb(176, 176, 176);
    margin: 0 auto;
    width: 100%;
}

/* IMAGES */
.round-preview {
    border: 1px solid var(--orange);
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

.shop-carousel-image {
    height: 20vh;
}

.product-details-img {
    border-radius: 5px;
    height: 250px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.toggle-img{
    display: inline-block;
    border-radius: 5px;
    height: 45px;
    margin-top: 10px;
    width: 45px;
}
  
.toggle-img:hover {
    border: 2px solid var(--orange);
}

.table-image{
    height: 40px;
    width: 40px;
  }

.cart-img{
    height: 70px;
    width: 70px;
  }

.cart-img-sm{
    height: 50px;
    width: 50px;
  }

.cat-text {
    background-color: rgba(0, 14, 9, 0.277);
    padding: 15px 100%;
    position: absolute;
    top: 0px;
    z-index: 100;
}

.product-card {
    min-width: 250px;
    width: 250px;
}

.category-box, .product-box {
    display: flex;
    border-radius: 10px;
    height: 200px;
    justify-content: center;
    min-width: 180px;
    overflow: hidden;
    position: relative;
    width: 180px;
}

.product-box {
    height: 250px;
    min-width: 250px;
    width: 250px;
}

.category-box:not(:last-child), .product-card:not(:last-child) {
    margin-right: 18px;
}

.category-image, .product-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.category-image:hover, .product-image:hover {
    overflow: hidden;
    transform: scale(1.2);
}

/* END IMAGES */

.shop-section {
    margin-top: 50px;
}

.shop-section:last-child {
    margin-bottom: 100px;
}

.underline {
    background-color: var(--orange);
    height: 5px;
    margin-bottom: 40px;
    margin-top: 10px;
    width: 60px;
}

.wrapper {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
}

.contact-bar {
    align-items: center;
    background-color: var(--orange);
    color: var(--white);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 25px;
}

.search {
    margin: 0;
    width: 100%;
}

.cart-number {
align-items: center;
background-color: var(--orange);
border-radius: 20%;
color: var(--white);
display: flex;
justify-content: center;
height: 20px;
width: 20px;
}

.product-qty {
    border: none;
    width: 25px;
}

.qty-toggle {
    border: none;
    border-radius: 5px;
    background-color: var(--orange);
    color: var(--white);
    font-size: 20px;
    height: 30px;
    width: 30px;
}

.orange-btn{
    border-radius: 5px;
    border: none;
    background-color: var(--orange);
    color: var(--white);
    height: 35px;
    padding: 0 10px;
}

.red-btn{
    border-radius: 5px;
    border: none;
    background-color: var(--red);
    color: var(--white);
    height: 35px;
}

.remove{
    border: none;
    border-radius: 5px;
    background-color: rgb(255, 243, 243);
    color: var(--red);
    font-size: 14px;
    padding: 5px 10px;
    width: fit-content;
}

.remove:hover{
    background-color: rgb(255, 224, 224);
}

/* CHECKOUT STEPS */
.step {
    border: 0;
    color: gray;
    cursor: default;
    float: left;
    font-size: 12px;
    height: auto;
    /* letter-spacing: 1px; */
    line-height: 30px;
    margin: 0;
    padding: 5px 10px 5px 15px;
    text-decoration: none;
}

.active-step{
    color: #fff;
    background-color: var(--orange);
}
.inactive-step {
    background-color: #eee;
}

@media (min-width: 768px) {
    .shop-carousel-image {
        height: 30vh;
    }
    .product-details-img {
        height: 300px;
        width: 80%;
        background-size: cover;
    }
    .category-box {
        height: 250px;
        min-width: 200px;
        width: 200px;
    }
}

@media (min-width: 992px) {
    .search {
        margin: 0 auto;
        width: 50%;
    }
    .shop-carousel-image {
        height: 40vh;
    }
}